.cookiebar {
  font-size: 1rem;
  background: $brand-color-lighter;
  z-index: 9999;
}

.cookiebar__button {
  background: $accent-color;
  transition: 250ms ease-in-out;
  &:hover {
    background: white;
    color: $text-color;
  }
}

.cookiebar__link {
  transition: 250ms ease-in-out;
  border-bottom: 1px solid white;
  text-decoration: none;
  &:hover {
    border-bottom: 1px solid rgba(white, 0);
  }
}

.cookiebar__message {
  margin-right: 0;
}
