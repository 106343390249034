.mod_article.cta-contact {
  padding: 0;
  @include screen(xlarge) {
    padding: 0 $sideSpace;
  }
  margin: 0;
  max-width: $max-width;
  margin: 0 auto;
  
  .ce_headline {
    width: 100%;
    color: $text-color;
    position: relative;
    margin-bottom: 0;
    padding: 0 $sideSpace calc( #{$topBottomSpace} * 0.33);
    @include screen(xlarge) {
      padding: 0 0 calc( #{$topBottomSpace} * 0.33); 
    }
    text-align: center;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      top: 0; bottom: 0;
      background: white;
      left: -9999px; right: -9999px;
      z-index: -1;
    }
  }
  
  .ce_text {
    padding: calc( #{$topBottomSpace} * 0.66) $sideSpace;
    @include screen(xlarge) {
      padding: calc( #{$topBottomSpace} * 0.66) 0;
    }
    
    h3 {
      margin-bottom: 0;
      line-height: 1;
      font-size: 1.4em;
      font-weight: 600;
    }
    
    em {
      margin-left: 0.1rem;
    }
  }
  
  .ce_image {
    overflow: hidden;
    @include screen(xlarge) {
      height: 22.5rem;
    
      .image_container {
        height: 100%;
        position: relative;

        img {
          position: absolute;
          top: 0;
        }
      }
    }
  }
  
  // LARGE
  @include screen(large) {
    display: flex;
    flex-wrap: wrap;
    
    .ce_text {
      align-self: center;
      width: percentage(5/12);
    }
    
    .ce_image {
      order: 1;
      width: percentage(7/12);
    }
  }
}