.slick.history {
  
  .slick-container {
    display: flex;
    position: relative;
    
    &::before {
      content: "";
      position: absolute;
      left: -9999px;
      right: -9999px;
      height: 1px;
      top: 5rem;
      background-color: $accent-color;
    }
  }
  
  .slick-slide:focus {
    outline: 0;
  }
  
  .ce_text {
    text-align: center;
    
    h3 {
      position: relative;
      padding: 1.5rem 0 !important;
      
      &::after {
        content: "+";
        position: absolute;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        bottom: -1.25rem;
        font-weight: 600;
        font-size: 1.325em;
        color: $accent-color;
      }
    }
    
    p {
      padding: 0 $sideSpace;
    }
    
    figure {
      outline: 1px solid white;
      margin-bottom: 0.75rem;
    }
  }
  
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    height: 3rem;
    width: 3rem;
    border: 0;
    border-radius: 0;
    
    &:focus {
      outline: 0;
    }
    
    &:hover {
      opacity: 0.66;
    }
  }
  
  .slick-prev {
    left: -1rem;
    @include screen(medium) {
      left: -1.675rem;  
    }
    @include screen(large) {
      left: -2.25rem;
    }
    @include screen(xlarge) {
      left: -3rem;
    }
    background: $accent-color url('../../files/assets/icons/ixxact-icon-arrow-left.svg') center center / 0.75rem auto no-repeat;
  }
  
  .slick-next {
    right: -1rem;
    @include screen(medium) {
      right: -1.675rem;
    }
    @include screen(large) {
      right: -2.25rem;
    }
    @include screen(xlarge) {
      right: -3rem;
    }
    background: $accent-color url('../../files/assets/icons/ixxact-icon-arrow-right.svg') center center / 0.75rem auto no-repeat;
  }
  
  .slick-disabled {
    opacity: 0;
    pointer-events: none;
  }
}