.logo {
  width: 6.25rem;
  transition: width 250ms ease-in-out;
  position: relative;
  font: 0/0 a;
  z-index: 8888;
  transform: translateZ(0);
  
  a {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
  }
  
  // LARGE
  @include screen(large) { 
    #navigation & {
      width: 7.5rem; 
    }
  }
}
