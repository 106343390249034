.mod_newsreader {
  
  &.news .layout_full {

    // LARGE-
    @include screen(large) {
      display: flex;
      flex-wrap: wrap;

      > * {
        width: 100%;
      }

      h1 {
        margin-bottom: calc( #{$topBottomSpace} * 0.33 );
      }

      figure {
        width: percentage(1/3);
        margin-top: 0.5rem;
      }

      .ce_text {
        width: percentage(2/3);
        padding-left: 2.5%;
      }
    }
  }
}
