%list {
  
  ul {
  position: relative;
  font-size: 1.125rem;
  margin-left: 1.5rem;

    & + p {
      margin-top: calc( #{$topBottomSpace} * 0.33);
    }
    
    li {
      padding-left: 1rem;
      font-style: italic;
    }

    li::before {
      content: "+";
      position: absolute;
      left: 0;
      transform: rotate(45deg);
      color: $accent-color;
      font-style: normal;
    }
  }
}

.ce_text {
  @extend %list;
  
  ul {
    margin-bottom: calc( #{$topBottomSpace} * 0.167 );
  }
}

.ce_list {
  @extend %list;
}