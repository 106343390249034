.ce_accordion.block {
  margin-bottom: calc( #{$topBottomSpace} * 0.15 ) !important;
  
  .ui-accordion-header {
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid rgba($brand-color, 0.25);
    padding-right: 1.5rem;
    
    &:focus {
      outline: 0;
    }
    
    &:hover .ui-accordion-header-icon {
      opacity: 1;
    }
    
    &.active .ui-accordion-header-icon {
      transform: translateY(-100%) rotate(-90deg);
    }
  }
  
    .ui-accordion-header-icon {
      position: absolute;
      top: 50%; transform: translateY(-100%);
      right: 0.5rem;
      width: 1rem;
      height: 1rem;
      background: url('../../files/assets/icons/ixxact-icon-arrow-down-dark.svg') no-repeat center center;
      opacity: 0.5;
      transition: 125ms ease-in-out;
    }
  
    .ui-accordion-content {
      margin-top: -1px;
    }
}