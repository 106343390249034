///// fonts: @font-face delarations
@import url("https://use.typekit.net/rxf1zrt.css");

///// styles
body {
  font-family: "source-sans-pro";
  font-size: 1.25rem;
  letter-spacing: 0.01rem;
}

