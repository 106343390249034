/// HERO
#hero {
  text-align: center;
  position: relative;
  top: -1px;
  
  // IMG
  .image_container {
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    @include aspect-ratio(2,3);
    
    picture {
      height: 100%;
    }
    
    img {
      height: 100%;
      width: auto;
    }
    
    // MEDIUM
    @include screen(medium) {
      @include aspect-ratio(1,1);
    }
    
    // LARGE
    @include screen(large) {
      @include aspect-ratio(16,9);
      
      img {
        width: 100%;
        height: auto;
      }
      
      picture {
        width: 100%;
      }
    }
    
    // LARGE
    @include screen(xlarge) {
      @include aspect-ratio(3,1);
    }    
  }
  
  // TEXT
  .hero-content {
    position: absolute;
    top: 55%; left: 5%;
    width: 90%;
    transform: translate3d(0, -50%, 0) !important; 
    z-index: 1;
    color: white;
    text-shadow: 0.125rem 0.125rem 0.5rem rgba(black, 0.125);
  }
}

/// START
#hero.home {
  
  // IMAGE
  img {
    opacity: 0.675;
    transform: translate3d(-50%,-50%,0) scale(1.0);
    transition: 375ms ease-in-out;
    // filter: blur(0.125rem);
    
    &.fadeIn {
      opacity: 1;
      // filter: blur(0);
      transform: translate3d(-50%,-50%,0) scale(1.05);
    }
  }
  
  // LARGE
  @include screen(large) {
    h1.ce_headline {
      font-size: 3rem;
    }
  }
  
  // LARGE
  @include screen(xlarge) {
    h1.ce_headline {
      font-size: 3.5rem;
    }
  }  
}

// JOBS
#hero.testimonial {

  // HERO CONTENT
  .hero-content {

    @include screen(medium) {
      left: 40%;
      width: 50%;
      text-align: left;
    }
    
    @include screen(large) {
      left: 50%;
      width: 45%;
      max-width: calc(#{$max-width} * 0.5);
      transform: translateX(-50%);
    }
  }
  
  .ce_text {
    position: relative;
    
    @include screen(medium) {
      background: rgba($brand-color, 0.75);
      padding: 1rem;
      
      &::after {
        content: "";
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        top: 1.5rem;
        left: -1.5rem;
        border-bottom: 1.5rem solid rgba($brand-color, 0.75);
        border-left: 1.5rem solid transparent;      
      }
    }
    
    blockquote {
      font-size: 1.5rem;
      @include screen(medium) {
        font-size: 1.375rem;
      }
      @include screen(large) {
        font-size: 1.5rem;
      }
      line-height: 1.325;
      margin-bottom: 1rem;
      
      &::before {
        content: "»";
        margin-right: 0.125rem;
      }
      
      &::after {
        content: "«";
        margin-left: 0.125rem;
      }
    }
    
    em {
      margin-left: 0.125rem;
    }
    
  }
  
  // IMAGE
  .ce_image {
    
    img {
      transform: translate3d(-35%, -50%, 0);
      opacity: 0.25;
      
      @include screen(medium) {
        transform: translate3d(-55%, -50%, 0);
        opacity: 1;
      }
      
      @include screen(large) {
        transform: translate3d(-50%, -50%, 0);
      }
      
      @include screen(xlarge) {
        transform: translate3d(-50%, -40%, 0);
      }
    }
  }
  
//  .hero-content {
//    left: 50%;
//    width: 45%;
//    max-width: calc(#{$max-width} * 0.5);
//    text-align: left;
//    transform: translateX(-50%);
//    padding: 1rem;
//    background: rgba($brand-color, 0.75);
//    


}

/// SLIDER
#hero .slick {
  background: $brand-color-lighter;
 
  @keyframes sliderAnimation {
    0% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
    
    100% {
      opacity: 1;
      transform: translate3d(0, -50%, 0);
    }
  }
  
  .slick-dots {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: -1.5rem;
    
    button {
      font: 0/0 a;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 100%;
      margin: 0.25rem;
      padding: 0;
      border: 2px solid $brand-color-lighter;
      
      &:hover {
        background: $brand-color-lighter;
      }
    }
    
    & .slick-active button {
      background: $brand-color-lighter;
    }
  }
  
  .slick-active > .hero-content {
    opacity: 0;
    animation: sliderAnimation 500ms ease-in-out 750ms forwards;
  }
}
