///// base

/// box-sizing
html {
  box-sizing: border-box;
}

* , *::before, *::after {
  box-sizing: inherit;
}


/// font-smoothing
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    color: $text-color;
    font: normal 16px / 1.5 $text-font-stack;
  hyphens: auto;  
}


/// invisible
.invisible {
  @include visually-hidden;
}


// responsive images
img {
  width: 100%;
  height: auto;
  vertical-align: top;
}

// hacks
.bg-orange a[href^="tel"],
.cta-contact a[href^="tel"] {
  border: 0 !important;
  color: white !important;  
}