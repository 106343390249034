// var
$backToTopBackgroundColor: $accent-color;

// back-to-top-link
#backToTop {
  position: fixed;
  transform: translateZ(0);
  bottom: 0;
  right: 0; 
  display: inline-block;
  height: 2rem; 
  width: 2rem;
  background:  $backToTopBackgroundColor url('../../files/assets/icons/ixxact-icon-arrow-up.svg') 0.25rem 0.375rem/1rem auto no-repeat;
  opacity: 0;
  transition: 250ms ease-in-out;
  z-index: 6666;
  
    &::after, &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: 2rem solid $backToTopBackgroundColor;
      border-left: 2rem solid transparent;
    }

    &::before {
      top: -2rem;
      left: 0;
    }

    &::after {
      top: 0;
      left: -2rem;
    }
  
  &.show {
    opacity: 0.5;
    transition: 500ms ease-in-out;
    
    &:hover {
      opacity: 1;
      transition: 250ms ease-in-out;
    }
  }
}