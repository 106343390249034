@charset "UTF-8";
@import url("https://use.typekit.net/rxf1zrt.css");
html, body, div, span, applet, object, iframe, h1, h2, .h2-low, .mod_article.teaser-subpages:not(.small) .ce_linkteaser h3, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: "";
  content: none; }

q:before, q:after {
  content: "";
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

html {
  box-sizing: border-box; }

*, *::before, *::after {
  box-sizing: inherit; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0a202d;
  font: normal 16px/1.5 sans-serif;
  hyphens: auto; }

.invisible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

img {
  width: 100%;
  height: auto;
  vertical-align: top; }

.bg-orange a[href^="tel"],
.cta-contact a[href^="tel"] {
  border: 0 !important;
  color: white !important; }

.rs-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .rs-columns > h1, .rs-columns > h2, .rs-columns > .h2-low, .mod_article.teaser-subpages:not(.small) .ce_linkteaser .rs-columns > h3, .rs-columns > h3, .rs-columns > h4, .rs-columns > h5, .rs-columns > h6 {
    width: 100%; }

.rs-column {
  width: 100%; }
  @media (max-width: 320px) {
    .rs-column.-small-col-1-1 {
      width: calc(100% - 0%); }
    .rs-column.-small-col-2-1 {
      width: calc(50% - 1.25%); }
    .rs-column.-small-col-2-2 {
      width: calc(100% - 1.25%); }
    .rs-column.-small-col-3-1 {
      width: calc(33.33333% - 1.66667%); }
    .rs-column.-small-col-3-2 {
      width: calc(66.66667% - 1.66667%); }
    .rs-column.-small-col-3-3 {
      width: calc(100% - 1.66667%); }
    .rs-column.-small-col-4-1 {
      width: calc(25% - 1.875%); }
    .rs-column.-small-col-4-2 {
      width: calc(50% - 1.875%); }
    .rs-column.-small-col-4-3 {
      width: calc(75% - 1.875%); }
    .rs-column.-small-col-4-4 {
      width: calc(100% - 1.875%); }
    .rs-column.-small-col-5-1 {
      width: calc(20% - 2%); }
    .rs-column.-small-col-5-2 {
      width: calc(40% - 2%); }
    .rs-column.-small-col-5-3 {
      width: calc(60% - 2%); }
    .rs-column.-small-col-5-4 {
      width: calc(80% - 2%); }
    .rs-column.-small-col-5-5 {
      width: calc(100% - 2%); }
    .rs-column.-small-col-6-1 {
      width: calc(16.66667% - 2.08333%); }
    .rs-column.-small-col-6-2 {
      width: calc(33.33333% - 2.08333%); }
    .rs-column.-small-col-6-3 {
      width: calc(50% - 2.08333%); }
    .rs-column.-small-col-6-4 {
      width: calc(66.66667% - 2.08333%); }
    .rs-column.-small-col-6-5 {
      width: calc(83.33333% - 2.08333%); }
    .rs-column.-small-col-6-6 {
      width: calc(100% - 2.08333%); } }
  @media (min-width: 541px) {
    .rs-column.-medium-col-1-1 {
      width: calc(100% - 0%); }
    .rs-column.-medium-col-2-1 {
      width: calc(50% - 1.25%); }
    .rs-column.-medium-col-2-2 {
      width: calc(100% - 1.25%); }
    .rs-column.-medium-col-3-1 {
      width: calc(33.33333% - 1.66667%); }
    .rs-column.-medium-col-3-2 {
      width: calc(66.66667% - 1.66667%); }
    .rs-column.-medium-col-3-3 {
      width: calc(100% - 1.66667%); }
    .rs-column.-medium-col-4-1 {
      width: calc(25% - 1.875%); }
    .rs-column.-medium-col-4-2 {
      width: calc(50% - 1.875%); }
    .rs-column.-medium-col-4-3 {
      width: calc(75% - 1.875%); }
    .rs-column.-medium-col-4-4 {
      width: calc(100% - 1.875%); }
    .rs-column.-medium-col-5-1 {
      width: calc(20% - 2%); }
    .rs-column.-medium-col-5-2 {
      width: calc(40% - 2%); }
    .rs-column.-medium-col-5-3 {
      width: calc(60% - 2%); }
    .rs-column.-medium-col-5-4 {
      width: calc(80% - 2%); }
    .rs-column.-medium-col-5-5 {
      width: calc(100% - 2%); }
    .rs-column.-medium-col-6-1 {
      width: calc(16.66667% - 2.08333%); }
    .rs-column.-medium-col-6-2 {
      width: calc(33.33333% - 2.08333%); }
    .rs-column.-medium-col-6-3 {
      width: calc(50% - 2.08333%); }
    .rs-column.-medium-col-6-4 {
      width: calc(66.66667% - 2.08333%); }
    .rs-column.-medium-col-6-5 {
      width: calc(83.33333% - 2.08333%); }
    .rs-column.-medium-col-6-6 {
      width: calc(100% - 2.08333%); } }
  @media (min-width: 769px) {
    .rs-column.-large-col-1-1 {
      width: calc(100% - 0%); }
    .rs-column.-large-col-2-1 {
      width: calc(50% - 1.25%); }
    .rs-column.-large-col-2-2 {
      width: calc(100% - 1.25%); }
    .rs-column.-large-col-3-1 {
      width: calc(33.33333% - 1.66667%); }
    .rs-column.-large-col-3-2 {
      width: calc(66.66667% - 1.66667%); }
    .rs-column.-large-col-3-3 {
      width: calc(100% - 1.66667%); }
    .rs-column.-large-col-4-1 {
      width: calc(25% - 1.875%); }
    .rs-column.-large-col-4-2 {
      width: calc(50% - 1.875%); }
    .rs-column.-large-col-4-3 {
      width: calc(75% - 1.875%); }
    .rs-column.-large-col-4-4 {
      width: calc(100% - 1.875%); }
    .rs-column.-large-col-5-1 {
      width: calc(20% - 2%); }
    .rs-column.-large-col-5-2 {
      width: calc(40% - 2%); }
    .rs-column.-large-col-5-3 {
      width: calc(60% - 2%); }
    .rs-column.-large-col-5-4 {
      width: calc(80% - 2%); }
    .rs-column.-large-col-5-5 {
      width: calc(100% - 2%); }
    .rs-column.-large-col-6-1 {
      width: calc(16.66667% - 2.08333%); }
    .rs-column.-large-col-6-2 {
      width: calc(33.33333% - 2.08333%); }
    .rs-column.-large-col-6-3 {
      width: calc(50% - 2.08333%); }
    .rs-column.-large-col-6-4 {
      width: calc(66.66667% - 2.08333%); }
    .rs-column.-large-col-6-5 {
      width: calc(83.33333% - 2.08333%); }
    .rs-column.-large-col-6-6 {
      width: calc(100% - 2.08333%); } }

#wrapper {
  overflow-x: hidden; }
  @supports (display: flex) {
    #wrapper {
      display: flex;
      min-height: 100vh;
      flex-direction: column; } }

#container {
  background: white; }
  @supports (display: flex) {
    #container {
      flex: 1 0 auto; } }
  .no-header #container {
    margin-top: 4.8125rem; }

body {
  font-family: "source-sans-pro";
  font-size: 1.25rem;
  letter-spacing: 0.01rem; }

h1, .h1, h2, .h2-low, .mod_article.teaser-subpages:not(.small) .ce_linkteaser h3, .h2, h3, .h3 {
  outline: 1px solid rgba(0, 0, 255, 0.25);
  outline: 0;
  font-weight: 300;
  margin-bottom: calc( 6rem * 0.167); }

h1, .h1 {
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 1.25; }

h2, .h2-low, .mod_article.teaser-subpages:not(.small) .ce_linkteaser h3, .h2 {
  text-transform: uppercase;
  font-size: 1.75rem;
  line-height: 1.375; }

.h2-low {
  text-transform: none;
  font-weight: 400;
  font-size: 2.125rem; }

h3, .h3 {
  font-size: 1.375rem;
  line-height: 1.25;
  font-weight: 400; }

p {
  margin-bottom: calc( 6rem * 0.125);
  font-size: 1.125rem;
  line-height: 1.5; }
  p .tel::before {
    content: "T ";
    font-weight: 700;
    font-size: 0.875em; }
  p .fax::before {
    content: "F ";
    font-weight: 700;
    font-size: 0.875em; }
  p.info {
    font-size: 1.125rem;
    color: rgba(10, 32, 45, 0.5); }

i, em {
  font-style: italic; }

b, strong {
  font-style: normal;
  font-weight: 600; }

.ce_text ul, .ce_list ul {
  position: relative;
  font-size: 1.125rem;
  margin-left: 1.5rem; }
  .ce_text ul + p, .ce_list ul + p {
    margin-top: calc( 6rem * 0.33); }
  .ce_text ul li, .ce_list ul li {
    padding-left: 1rem;
    font-style: italic; }
  .ce_text ul li::before, .ce_list ul li::before {
    content: "+";
    position: absolute;
    left: 0;
    transform: rotate(45deg);
    color: #ff6c37;
    font-style: normal; }

.ce_text ul {
  margin-bottom: calc( 6rem * 0.167); }

button, .cta-contact a, a.more, .more > a, .ce_hyperlink > a, .layout_simple > a, .back > a, form button.submit {
  letter-spacing: 0.05rem;
  font-weight: 600;
  opacity: 1;
  text-transform: uppercase;
  font-size: 1rem;
  transition: 250ms ease-in-out;
  cursor: pointer;
  color: #ff6c37;
  border: 1px solid #ff6c37;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem 0;
  display: inline-block; }
  button:hover, .cta-contact a:hover, a.more:hover, .more > a:hover, .ce_hyperlink > a:hover, .layout_simple > a:hover, .back > a:hover, form button.submit:hover {
    background: #ff6c37;
    color: white; }
  button:focus, .cta-contact a:focus, a.more:focus, .more > a:focus, .ce_hyperlink > a:focus, .layout_simple > a:focus, .back > a:focus, form button.submit:focus {
    outline: none; }
  button::moz-focus-inner, .cta-contact a::moz-focus-inner, a.more::moz-focus-inner, .more > a::moz-focus-inner, .ce_hyperlink > a::moz-focus-inner, .layout_simple > a::moz-focus-inner, .back > a::moz-focus-inner, form button.submit::moz-focus-inner {
    border: 0; }

.hero .hero-content button, .hero .hero-content .cta-contact a, .cta-contact .hero .hero-content a, .hero .hero-content a.more, .hero .hero-content .more > a, .hero .hero-content .ce_hyperlink > a, .hero .hero-content .layout_simple > a, .hero .hero-content .back > a {
  background: #ff6c37;
  color: white; }

a {
  text-decoration: none;
  color: currentColor; }

.container .ce_text:not(.cta-contact) a {
  color: #ff6c37;
  border-bottom: 1px solid rgba(255, 108, 55, 0); }
  .container .ce_text:not(.cta-contact) a:hover {
    border-bottom: 1px solid #ff6c37; }

.cta-contact a {
  color: white;
  border: 1px solid white; }
  .cta-contact a:hover {
    color: #ff6c37;
    background: white; }

.mod_newslist .back {
  text-align: center; }

.logo {
  width: 6.25rem;
  transition: width 250ms ease-in-out;
  position: relative;
  font: 0/0 a;
  z-index: 8888;
  transform: translateZ(0); }
  .logo a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  @media (min-width: 769px) {
    #navigation .logo {
      width: 7.5rem; } }

.nav-mobile-trigger {
  order: 1;
  height: 4rem;
  width: 3rem;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

#backToTop {
  position: fixed;
  transform: translateZ(0);
  bottom: 0;
  right: 0;
  display: inline-block;
  height: 2rem;
  width: 2rem;
  background: #ff6c37 url("../../files/assets/icons/ixxact-icon-arrow-up.svg") 0.25rem 0.375rem/1rem auto no-repeat;
  opacity: 0;
  transition: 250ms ease-in-out;
  z-index: 6666; }
  #backToTop::after, #backToTop::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 2rem solid #ff6c37;
    border-left: 2rem solid transparent; }
  #backToTop::before {
    top: -2rem;
    left: 0; }
  #backToTop::after {
    top: 0;
    left: -2rem; }
  #backToTop.show {
    opacity: 0.5;
    transition: 500ms ease-in-out; }
    #backToTop.show:hover {
      opacity: 1;
      transition: 250ms ease-in-out; }

.icon-hamburger {
  position: relative; }
  .icon-hamburger, .icon-hamburger::before, .icon-hamburger::after {
    width: 1.5rem;
    height: 0.1875rem;
    background: white;
    transition: 333ms ease-in-out; }
    .show-mobile-menu .icon-hamburger, .show-mobile-menu .icon-hamburger::before, .show-mobile-menu .icon-hamburger::after {
      background: #ff6c37;
      width: 1.675rem; }
    .nav-mobile-trigger:hover .icon-hamburger, .nav-mobile-trigger:hover .icon-hamburger::before, .nav-mobile-trigger:hover .icon-hamburger::after {
      background: #ff6c37; }
  .icon-hamburger::before, .icon-hamburger::after {
    content: "";
    position: absolute;
    left: 0;
    transform: rotate(0deg); }
  .show-mobile-menu .icon-hamburger {
    background-color: transparent; }
  .show-mobile-menu .nav-mobile-trigger:hover .icon-hamburger {
    background-color: transparent; }
  .icon-hamburger:before {
    top: -0.5rem; }
    .show-mobile-menu .icon-hamburger:before {
      top: 0;
      transform: rotate(45deg); }
  .icon-hamburger:after {
    top: 0.5rem; }
    .show-mobile-menu .icon-hamburger:after {
      top: 0;
      transform: rotate(-45deg); }

figcaption {
  color: rgba(10, 32, 45, 0.5);
  font-size: 1.125rem;
  margin-top: 0.5rem; }

.container figure {
  margin-bottom: calc( 6rem * 0.167); }

.nav-sub [itemprop="name"] {
  position: relative;
  display: inline-block;
  font-size: 1.125rem;
  padding: 0.5rem 0.75rem;
  line-height: 100%; }
  .nav-sub [itemprop="name"]::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 40%;
    margin-left: 0.75rem;
    background: rgba(10, 32, 45, 0.5) !important; }

.nav-sub .last [itemprop="name"]::after {
  content: none; }

.nav-main a, .nav-main span {
  text-decoration: none;
  display: inline-block;
  color: white;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 100%;
  padding: 0.75rem;
  border-radius: 0.25rem;
  cursor: pointer; }
  @media (min-width: 769px) {
    .nav-main a, .nav-main span {
      padding: 0.75rem 0.5rem; } }
  @media (min-width: 1025px) {
    .nav-main a, .nav-main span {
      padding: 0.75rem; } }

.nav-main ul.level_1 > li > a, .nav-main ul.level_1 > li > span {
  transition: 125ms ease-in-out; }
  .nav-main ul.level_1 > li > a:hover, .nav-main ul.level_1 > li > span:hover {
    background: #0a202d; }

.nav-main ul.level_1 span.trail,
.nav-main ul.level_1 span.active {
  font-weight: 700; }

.nav-main ul.level_1 > li.submenu > a, .nav-main ul.level_1 > li.submenu > span {
  position: relative;
  padding-right: 1.875rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.nav-main ul.level_1 > li.submenu:hover > a, .nav-main ul.level_1 > li.submenu:hover > span {
  background: #0a202d; }

.nav-main ul.level_1 > li.submenu > span::after {
  content: "";
  background: url("../../files/assets/icons/ixxact-icon-arrow-down.svg") no-repeat center/0.75rem 0.75rem;
  top: 50%;
  right: 0;
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.75rem;
  transform: translateY(-50%);
  position: absolute;
  transition: transform 125ms ease-in-out; }

@media (max-width: 768px) {
  .nav-main ul.level_1 > li.submenu.open > span::after {
    transform: translateY(-50%) rotate(-90deg);
    right: 0.125rem; } }

.nav-main ul.level_2 a, .nav-main ul.level_2 span {
  width: 100%;
  font-size: 1.125rem;
  font-weight: 400;
  min-width: 12.5rem;
  transition: 250ms ease-in-out;
  border-radius: 0;
  opacity: 0.66; }
  .nav-main ul.level_2 a.first, .nav-main ul.level_2 span.first {
    padding-top: 1rem; }
  .nav-main ul.level_2 a.last, .nav-main ul.level_2 span.last {
    padding-bottom: 1rem; }
  .nav-main ul.level_2 a:hover, .nav-main ul.level_2 span:hover {
    background: #1c2c3a;
    opacity: 1; }
  .nav-main ul.level_2 a.active, .nav-main ul.level_2 span.active {
    background: none;
    opacity: 1;
    cursor: default; }

.nav-main {
  position: relative; }
  @media (max-width: 768px) {
    .nav-main {
      padding: 7.5rem 0; }
      .nav-main ul.level_1 {
        margin-left: 5%;
        margin-right: 5%; }
      .nav-main ul.level_2 {
        display: none; }
        .nav-main ul.level_2 a, .nav-main ul.level_2 span {
          padding-left: 1.5rem; }
          .nav-main ul.level_2 a:hover, .nav-main ul.level_2 span:hover {
            background: none; }
      .nav-main li.submenu.open ul.level_2 {
        display: block; }
      .nav-main li span, .nav-main li a {
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
        width: 100%; } }
  @media (min-width: 769px) {
    .nav-main {
      margin-left: auto; }
      .nav-main ul.level_1 {
        display: flex; }
      .nav-main ul.level_2 {
        position: absolute;
        background: #0a202d;
        color: white;
        z-index: 1;
        opacity: 0;
        pointer-events: none;
        transition: opacity 125ms ease-in-out;
        overflow: hidden;
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem; }
      .nav-main li.submenu:hover ul.level_2 {
        opacity: 1;
        pointer-events: auto; } }

.nav-book ul {
  display: flex;
  justify-content: center; }

.nav-book li {
  padding: 1.5rem; }

.nav-contact {
  margin-left: auto;
  z-index: 9999; }
  .nav-contact ul {
    display: flex; }
  .nav-contact ul > li {
    height: 4rem;
    width: 2.75rem;
    transition: background 250ms ease-in-out; }
  .nav-contact a {
    height: 4rem;
    width: 2.75rem;
    display: inline-block; }
  .nav-contact li.tel-contact {
    background: url("../../files/assets/icons/ixxact-icon-tel-bright.svg") no-repeat center center/1.5rem auto; }
    .nav-contact li.tel-contact:hover {
      background-image: url("../../files/assets/icons/ixxact-icon-tel-orange.svg"); }
  .nav-contact li.mail-contact {
    background: url("../../files/assets/icons/ixxact-icon-mail-bright.svg") no-repeat center center/1.5rem auto; }
    .nav-contact li.mail-contact:hover {
      background-image: url("../../files/assets/icons/ixxact-icon-mail-orange.svg"); }
  @media (min-width: 769px) {
    .nav-contact {
      margin-left: 0; }
      .nav-contact ul > li {
        height: 2.75rem;
        width: 2.25rem; }
      .nav-contact ul > li > a {
        height: 2.75rem;
        width: 2.25rem; }
      .nav-contact li.tel-contact {
        background-size: 1.25rem auto; }
      .nav-contact li.mail-contact {
        background-size: 1.25rem auto; } }
  @media (min-width: 1025px) {
    .nav-contact {
      margin-left: 1rem; } }

.dlh_googlemap {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  width: 100%;
  overflow: hidden; }
  .dlh_googlemap > * {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  @media (min-width: 541px) {
    .dlh_googlemap {
      padding-bottom: 75%; } }
  @media (min-width: 769px) {
    .dlh_googlemap {
      padding-bottom: 50%; } }

.ce_table table {
  width: 100%;
  border: 1px solid rgba(10, 32, 45, 0.125); }

.ce_table table, .ce_table td, .ce_table tr, .ce_table th {
  border-collapse: collapse;
  text-align: left; }

.ce_table td, .ce_table tr, .ce_table th {
  padding: 0.5rem;
  border-collapse: collapse;
  font-size: 1.125rem; }

.ce_table th {
  border-bottom: 1px solid rgba(10, 32, 45, 0.125);
  background: rgba(10, 32, 45, 0.1);
  font-weight: 600; }

.ce_table th:not(.col_last), .ce_table td:not(.col_last) {
  border-right: 1px solid rgba(10, 32, 45, 0.125); }

.ce_table tr.even {
  background-color: rgba(10, 32, 45, 0.05); }

@media (max-width: 768px) {
  .ce_table table {
    border: 0; }
  .ce_table thead {
    display: none; }
  .ce_table td, .ce_table tr, .ce_table th, .ce_table td:not(.col_last) {
    border: 0; }
  .ce_table td:not(.col_last) {
    border-bottom: 1px solid rgba(10, 32, 45, 0.125); }
  .ce_table tr {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
    padding: 0;
    border: 1px solid rgba(10, 32, 45, 0.125); }
    .ce_table tr.even {
      background: none; }
  .ce_table td {
    padding: 0.5rem;
    position: relative;
    padding-left: 8.5rem; }
    .ce_table td.col_last {
      border-bottom: 0; }
  .ce_table td::before {
    content: attr(title);
    overflow: hidden;
    width: 8rem;
    left: 0.5rem;
    position: absolute;
    height: 100%;
    font-weight: 600;
    border-right: 1px solid rgba(10, 32, 45, 0.125);
    background: rgba(10, 32, 45, 0.05);
    padding: 0.5rem;
    margin: -0.5rem 0.5rem -0.5rem -0.5rem; } }

.ce_form h2, .ce_form .h2-low, .ce_form .mod_article.teaser-subpages:not(.small) .ce_linkteaser h3, .mod_article.teaser-subpages:not(.small) .ce_linkteaser .ce_form h3 {
  text-align: center; }

form {
  max-width: 768px;
  margin: 0 auto; }
  form .widget {
    margin-bottom: 0.5rem;
    position: relative; }
    form .widget-submit {
      text-align: center; }
  form label {
    position: absolute;
    top: 1.25rem;
    left: 1rem;
    transition: 250ms ease-in-out;
    opacity: 1; }
  form input, form textarea {
    border: 0;
    width: 100%;
    height: 100%;
    padding: 2rem 1rem 0.5rem 1rem;
    font-family: "source-sans-pro";
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0.01rem;
    box-shadow: 0 1px 0 rbga(#ff6c37, 0);
    transition: box-shadow 250ms ease-in-out;
    background: rgba(10, 32, 45, 0.05); }
    form input:focus, form textarea:focus {
      outline: 0;
      box-shadow: 0 1px 0 #ff6c37; }
    form input:valid, form textarea:valid {
      box-shadow: 0 1px 0 #ff6c37; }
    form input[data-empty="false"] + label,
    form input:valid + label,
    form input:focus + label, form textarea[data-empty="false"] + label,
    form textarea:valid + label,
    form textarea:focus + label {
      top: 0.5rem;
      font-size: 1rem;
      opacity: 0.5; }
  form .cta-contact a.submit, .cta-contact form a.submit, form a.submit.more, form .more > a.submit, form .ce_hyperlink > a.submit, form .layout_simple > a.submit, form .back > a.submit, form button.submit {
    background: none;
    border-radius: 0;
    font-family: "source sans pro"; }

form.contact input, form.contact textarea {
  background: #2e3a48;
  color: white; }

fieldset.checkbox_container {
  border: 1px solid rgba(255, 255, 255, 0.33);
  padding: 1rem;
  margin-top: -0.5rem; }
  fieldset.checkbox_container label {
    position: static;
    font-size: 0.875em; }
  fieldset.checkbox_container input.checkbox {
    width: auto;
    height: auto; }
    fieldset.checkbox_container input.checkbox[data-empty="false"] + label,
    fieldset.checkbox_container input.checkbox:valid + label,
    fieldset.checkbox_container input.checkbox:focus + label {
      top: 0;
      font-size: 0.875em;
      opacity: 1; }
  fieldset.checkbox_container a {
    opacity: 0.66;
    transition: 250ms ease-in-out;
    border-bottom: 1px solid currentColor; }
    fieldset.checkbox_container a:hover {
      opacity: 1; }

.ce_accordion.block {
  margin-bottom: calc( 6rem * 0.15) !important; }
  .ce_accordion.block .ui-accordion-header {
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid rgba(10, 32, 45, 0.25);
    padding-right: 1.5rem; }
    .ce_accordion.block .ui-accordion-header:focus {
      outline: 0; }
    .ce_accordion.block .ui-accordion-header:hover .ui-accordion-header-icon {
      opacity: 1; }
    .ce_accordion.block .ui-accordion-header.active .ui-accordion-header-icon {
      transform: translateY(-100%) rotate(-90deg); }
  .ce_accordion.block .ui-accordion-header-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    right: 0.5rem;
    width: 1rem;
    height: 1rem;
    background: url("../../files/assets/icons/ixxact-icon-arrow-down-dark.svg") no-repeat center center;
    opacity: 0.5;
    transition: 125ms ease-in-out; }
  .ce_accordion.block .ui-accordion-content {
    margin-top: -1px; }

@media (max-width: 768px) {
  .ce_gallery ul > li {
    margin-bottom: 0.5rem; }
    .ce_gallery ul > li:last-child {
      margin-bottom: 0; } }

@media (min-width: 769px) {
  .ce_gallery ul {
    display: flex; }
  .ce_gallery ul > li {
    margin-right: 0.25rem; }
    .ce_gallery ul > li:last-child {
      margin-right: 0; } }

.slick.history .slick-container {
  display: flex;
  position: relative; }
  .slick.history .slick-container::before {
    content: "";
    position: absolute;
    left: -9999px;
    right: -9999px;
    height: 1px;
    top: 5rem;
    background-color: #ff6c37; }

.slick.history .slick-slide:focus {
  outline: 0; }

.slick.history .ce_text {
  text-align: center; }
  .slick.history .ce_text h3 {
    position: relative;
    padding: 1.5rem 0 !important; }
    .slick.history .ce_text h3::after {
      content: "+";
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      bottom: -1.25rem;
      font-weight: 600;
      font-size: 1.325em;
      color: #ff6c37; }
  .slick.history .ce_text p {
    padding: 0 5%; }
  .slick.history .ce_text figure {
    outline: 1px solid white;
    margin-bottom: 0.75rem; }

.slick.history .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  height: 3rem;
  width: 3rem;
  border: 0;
  border-radius: 0; }
  .slick.history .slick-arrow:focus {
    outline: 0; }
  .slick.history .slick-arrow:hover {
    opacity: 0.66; }

.slick.history .slick-prev {
  left: -1rem;
  background: #ff6c37 url("../../files/assets/icons/ixxact-icon-arrow-left.svg") center center/0.75rem auto no-repeat; }
  @media (min-width: 541px) {
    .slick.history .slick-prev {
      left: -1.675rem; } }
  @media (min-width: 769px) {
    .slick.history .slick-prev {
      left: -2.25rem; } }
  @media (min-width: 1025px) {
    .slick.history .slick-prev {
      left: -3rem; } }

.slick.history .slick-next {
  right: -1rem;
  background: #ff6c37 url("../../files/assets/icons/ixxact-icon-arrow-right.svg") center center/0.75rem auto no-repeat; }
  @media (min-width: 541px) {
    .slick.history .slick-next {
      right: -1.675rem; } }
  @media (min-width: 769px) {
    .slick.history .slick-next {
      right: -2.25rem; } }
  @media (min-width: 1025px) {
    .slick.history .slick-next {
      right: -3rem; } }

.slick.history .slick-disabled {
  opacity: 0;
  pointer-events: none; }

.ce_text.cta-contact {
  background: #ff6c37;
  color: white; }
  .ce_text.cta-contact figure {
    margin-bottom: 0; }
  .ce_text.cta-contact em {
    margin-left: 0.1rem; }
  .ce_text.cta-contact h3, .ce_text.cta-contact p {
    padding: 0 5%; }
  .ce_text.cta-contact h3 {
    margin-bottom: 0;
    line-height: 1;
    font-size: 1.4em;
    font-weight: 600; }
  .ce_text.cta-contact h3 + p .tel {
    margin-top: calc( 6rem * 0.125);
    display: inline-block; }
  .ce_text.cta-contact h3 {
    padding-top: calc( 6rem * 0.33); }
  .ce_text.cta-contact p:last-of-type {
    padding-bottom: calc( 6rem * 0.33); }
  @media (min-width: 769px) {
    .ce_text.cta-contact .image_container {
      position: relative;
      overflow: hidden; }
      .ce_text.cta-contact .image_container::before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 56.25%; }
      .ce_text.cta-contact .image_container img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0); } }

.mod_article.teaser-subpages .ce_linkteaser {
  position: relative;
  background: #0a202d;
  text-align: center; }
  .mod_article.teaser-subpages .ce_linkteaser .image_container {
    overflow: hidden; }
    .mod_article.teaser-subpages .ce_linkteaser .image_container img {
      opacity: 0.33;
      transform: scale(1);
      transition: 375ms ease-in-out;
      -webkit-backface-visibility: hidden; }
  .mod_article.teaser-subpages .ce_linkteaser:hover img {
    transform: scale(1.05);
    opacity: 1; }
  .mod_article.teaser-subpages .ce_linkteaser .content {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate3d(0, -50%, 0);
    transform-style: preserve-3d;
    opacity: 1;
    transition: 375ms ease-in-out; }
    .mod_article.teaser-subpages .ce_linkteaser .content h3 {
      color: white; }
  .mod_article.teaser-subpages .ce_linkteaser:hover .content {
    opacity: 0;
    transform: translate3D(0, -35%, 0); }
  .mod_article.teaser-subpages .ce_linkteaser .teaser {
    display: none; }

.mod_article.teaser-subpages.small .ce_linkteaser {
  z-index: 1;
  overflow: hidden; }
  .mod_article.teaser-subpages.small .ce_linkteaser.active {
    display: none; }
  .mod_article.teaser-subpages.small .ce_linkteaser .image_container {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    z-index: -1; }
  .mod_article.teaser-subpages.small .ce_linkteaser .content {
    position: relative;
    transform: translate3d(0, 0, 0);
    padding: 1.5rem 5%; }
    @media (min-width: 769px) {
      .mod_article.teaser-subpages.small .ce_linkteaser .content {
        padding: 2.25rem 5%; } }
    @media (min-width: 1025px) {
      .mod_article.teaser-subpages.small .ce_linkteaser .content {
        padding: 3rem 5%; } }

#navigation {
  position: fixed;
  z-index: 9999;
  transform: translateZ(0);
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem calc(5% * 0.5) 0.5rem 5%;
  background: rgba(46, 58, 72, 0); }
  @media (min-width: 769px) {
    #navigation {
      padding: 1rem calc(5% * 0.5) 1rem 5%; } }
  .no-header #navigation, #navigation.sticky {
    background: #2e3a48; }
  .show-mobile-menu #navigation {
    background: #2e3a48;
    transition: 250ms ease-in-out; }

.nav-main {
  display: none; }
  @media (max-width: 768px) {
    .has-mobile-menu .nav-main {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100vh;
      height: 100%;
      background: #0a202d;
      transition: opacity 250ms ease-in-out;
      opacity: 0;
      pointer-events: none;
      overflow: scroll; }
      .has-mobile-menu .nav-main::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 7.5rem;
        background: #0a202d;
        background: linear-gradient(to bottom, #0a202d, #0a202d 5rem, rgba(10, 32, 45, 0));
        z-index: 1; }
      .show-mobile-menu .has-mobile-menu .nav-main {
        opacity: 1;
        pointer-events: all; } }
  @media (min-width: 769px) {
    .nav-main {
      display: block; } }

@media (min-width: 769px) {
  .nav-mobile-trigger {
    display: none; } }

#hero {
  text-align: center;
  position: relative;
  top: -1px; }
  #hero .image_container {
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    position: relative;
    overflow: hidden; }
    #hero .image_container::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 150%; }
    #hero .image_container img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
    #hero .image_container picture {
      height: 100%; }
    #hero .image_container img {
      height: 100%;
      width: auto; }
    @media (min-width: 541px) {
      #hero .image_container {
        position: relative;
        overflow: hidden; }
        #hero .image_container::before {
          display: block;
          content: "";
          width: 100%;
          padding-top: 100%; }
        #hero .image_container img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0); } }
    @media (min-width: 769px) {
      #hero .image_container {
        position: relative;
        overflow: hidden; }
        #hero .image_container::before {
          display: block;
          content: "";
          width: 100%;
          padding-top: 56.25%; }
        #hero .image_container img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0); }
        #hero .image_container img {
          width: 100%;
          height: auto; }
        #hero .image_container picture {
          width: 100%; } }
    @media (min-width: 1025px) {
      #hero .image_container {
        position: relative;
        overflow: hidden; }
        #hero .image_container::before {
          display: block;
          content: "";
          width: 100%;
          padding-top: 33.33333%; }
        #hero .image_container img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0); } }
  #hero .hero-content {
    position: absolute;
    top: 55%;
    left: 5%;
    width: 90%;
    transform: translate3d(0, -50%, 0) !important;
    z-index: 1;
    color: white;
    text-shadow: 0.125rem 0.125rem 0.5rem rgba(0, 0, 0, 0.125); }

#hero.home img {
  opacity: 0.675;
  transform: translate3d(-50%, -50%, 0) scale(1);
  transition: 375ms ease-in-out; }
  #hero.home img.fadeIn {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(1.05); }

@media (min-width: 769px) {
  #hero.home h1.ce_headline {
    font-size: 3rem; } }

@media (min-width: 1025px) {
  #hero.home h1.ce_headline {
    font-size: 3.5rem; } }

@media (min-width: 541px) {
  #hero.testimonial .hero-content {
    left: 40%;
    width: 50%;
    text-align: left; } }

@media (min-width: 769px) {
  #hero.testimonial .hero-content {
    left: 50%;
    width: 45%;
    max-width: calc(1440px * 0.5);
    transform: translateX(-50%); } }

#hero.testimonial .ce_text {
  position: relative; }
  @media (min-width: 541px) {
    #hero.testimonial .ce_text {
      background: rgba(10, 32, 45, 0.75);
      padding: 1rem; }
      #hero.testimonial .ce_text::after {
        content: "";
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        top: 1.5rem;
        left: -1.5rem;
        border-bottom: 1.5rem solid rgba(10, 32, 45, 0.75);
        border-left: 1.5rem solid transparent; } }
  #hero.testimonial .ce_text blockquote {
    font-size: 1.5rem;
    line-height: 1.325;
    margin-bottom: 1rem; }
    @media (min-width: 541px) {
      #hero.testimonial .ce_text blockquote {
        font-size: 1.375rem; } }
    @media (min-width: 769px) {
      #hero.testimonial .ce_text blockquote {
        font-size: 1.5rem; } }
    #hero.testimonial .ce_text blockquote::before {
      content: "»";
      margin-right: 0.125rem; }
    #hero.testimonial .ce_text blockquote::after {
      content: "«";
      margin-left: 0.125rem; }
  #hero.testimonial .ce_text em {
    margin-left: 0.125rem; }

#hero.testimonial .ce_image img {
  transform: translate3d(-35%, -50%, 0);
  opacity: 0.25; }
  @media (min-width: 541px) {
    #hero.testimonial .ce_image img {
      transform: translate3d(-55%, -50%, 0);
      opacity: 1; } }
  @media (min-width: 769px) {
    #hero.testimonial .ce_image img {
      transform: translate3d(-50%, -50%, 0); } }
  @media (min-width: 1025px) {
    #hero.testimonial .ce_image img {
      transform: translate3d(-50%, -40%, 0); } }

#hero .slick {
  background: #2e3a48; }

@keyframes sliderAnimation {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, -50%, 0); } }
  #hero .slick .slick-dots {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: -1.5rem; }
    #hero .slick .slick-dots button, #hero .slick .slick-dots .cta-contact a, .cta-contact #hero .slick .slick-dots a, #hero .slick .slick-dots a.more, #hero .slick .slick-dots .more > a, #hero .slick .slick-dots .ce_hyperlink > a, #hero .slick .slick-dots .layout_simple > a, #hero .slick .slick-dots .back > a {
      font: 0/0 a;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 100%;
      margin: 0.25rem;
      padding: 0;
      border: 2px solid #2e3a48; }
      #hero .slick .slick-dots button:hover, #hero .slick .slick-dots .cta-contact a:hover, .cta-contact #hero .slick .slick-dots a:hover, #hero .slick .slick-dots a.more:hover, #hero .slick .slick-dots .more > a:hover, #hero .slick .slick-dots .ce_hyperlink > a:hover, #hero .slick .slick-dots .layout_simple > a:hover, #hero .slick .slick-dots .back > a:hover {
        background: #2e3a48; }
    #hero .slick .slick-dots .slick-active button, #hero .slick .slick-dots .slick-active .cta-contact a, .cta-contact #hero .slick .slick-dots .slick-active a, #hero .slick .slick-dots .slick-active a.more, #hero .slick .slick-dots .slick-active .more > a, #hero .slick .slick-dots .slick-active .ce_hyperlink > a, #hero .slick .slick-dots .slick-active .layout_simple > a, #hero .slick .slick-dots .slick-active .back > a {
      background: #2e3a48; }
  #hero .slick .slick-active > .hero-content {
    opacity: 0;
    animation: sliderAnimation 500ms ease-in-out 750ms forwards; }

.mod_article.container {
  margin: 6rem auto;
  padding: 0 5%;
  max-width: 1440px; }
  .mod_article.container > *.block:not(:last-child) {
    margin-bottom: calc( 6rem * 0.5); }

.mod_article.bg-orange, .mod_article.bg-blue {
  margin: 0 auto;
  padding: 6rem 5%;
  color: white;
  position: relative;
  z-index: 0; }
  .mod_article.bg-orange::after, .mod_article.bg-blue::after {
    content: "";
    position: absolute;
    left: -9999px;
    right: -9999px;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: red; }

.mod_article.bg-orange::after {
  background: #ff6c37; }

.mod_article.bg-orange:not(.cta-contact) .tel {
  font-size: 1.4em; }

.mod_article.bg-blue::after {
  background: #0a202d; }

.mod_article.max-width {
  max-width: 1440px;
  margin: 0 auto;
  padding: 6rem 5%; }

.mod_article.teaser-subpages {
  width: 100%; }
  .mod_article.teaser-subpages .ce_headline {
    width: 100%;
    text-align: center;
    margin-bottom: calc( 6rem * 0.33); }
  @media (min-width: 541px) {
    .mod_article.teaser-subpages {
      display: flex;
      flex-wrap: wrap; }
      .mod_article.teaser-subpages > .block {
        width: 50%; } }
  @media (min-width: 769px) {
    .mod_article.teaser-subpages > .block {
      width: 33.33333%; } }

.mod_article.teaser-subpages:not(.small) {
  margin: 6rem auto;
  padding: 0 5%;
  max-width: 1440px; }
  .mod_article.teaser-subpages:not(.small) .image_container {
    position: relative;
    overflow: hidden; }
    .mod_article.teaser-subpages:not(.small) .image_container::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 66.66667%; }
    .mod_article.teaser-subpages:not(.small) .image_container img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0); }
  .mod_article.teaser-subpages:not(.small) .ce_linkteaser h3 {
    font-weight: 400; }

@media (max-width: 768px) {
  .mod_article.teaser-subpages.small > .block {
    width: 100%; } }

@media (min-width: 769px) and (max-width: 1024px) {
  .mod_article.teaser-subpages.small > .block:nth-last-child(-n+2) {
    width: 50%; } }

@media (min-width: 1025px) {
  .mod_article.teaser-subpages.small > .block {
    flex: 1 0 auto;
    width: auto; } }

.mod_article.news {
  margin: 6rem auto;
  padding: 0 5%;
  max-width: 1440px; }

.mod_article.cta-contact {
  padding: 0;
  margin: 0;
  max-width: 1440px;
  margin: 0 auto; }
  @media (min-width: 1025px) {
    .mod_article.cta-contact {
      padding: 0 5%; } }
  .mod_article.cta-contact .ce_headline {
    width: 100%;
    color: #0a202d;
    position: relative;
    margin-bottom: 0;
    padding: 0 5% calc( 6rem * 0.33);
    text-align: center;
    z-index: 1; }
    @media (min-width: 1025px) {
      .mod_article.cta-contact .ce_headline {
        padding: 0 0 calc( 6rem * 0.33); } }
    .mod_article.cta-contact .ce_headline::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background: white;
      left: -9999px;
      right: -9999px;
      z-index: -1; }
  .mod_article.cta-contact .ce_text {
    padding: calc( 6rem * 0.66) 5%; }
    @media (min-width: 1025px) {
      .mod_article.cta-contact .ce_text {
        padding: calc( 6rem * 0.66) 0; } }
    .mod_article.cta-contact .ce_text h3 {
      margin-bottom: 0;
      line-height: 1;
      font-size: 1.4em;
      font-weight: 600; }
    .mod_article.cta-contact .ce_text em {
      margin-left: 0.1rem; }
  .mod_article.cta-contact .ce_image {
    overflow: hidden; }
    @media (min-width: 1025px) {
      .mod_article.cta-contact .ce_image {
        height: 22.5rem; }
        .mod_article.cta-contact .ce_image .image_container {
          height: 100%;
          position: relative; }
          .mod_article.cta-contact .ce_image .image_container img {
            position: absolute;
            top: 0; } }
  @media (min-width: 769px) {
    .mod_article.cta-contact {
      display: flex;
      flex-wrap: wrap; }
      .mod_article.cta-contact .ce_text {
        align-self: center;
        width: 41.66667%; }
      .mod_article.cta-contact .ce_image {
        order: 1;
        width: 58.33333%; } }

.mod_article.slider {
  margin: 6rem auto;
  padding: 0 5%;
  max-width: 1440px; }

.mod_newslist.news .layout_latest, .mod_newsarchive .layout_latest {
  margin-bottom: 1.5rem;
  position: relative;
  box-shadow: 1px 1px 6px rgba(10, 32, 45, 0.2); }
  .mod_newslist.news .layout_latest figure, .mod_newsarchive .layout_latest figure {
    overflow: hidden; }
    .mod_newslist.news .layout_latest figure img, .mod_newsarchive .layout_latest figure img {
      transform: scale(1);
      transform-style: preserve-3d;
      transition: 250ms ease-in-out;
      -webkit-backface-visibility: hidden; }
  .mod_newslist.news .layout_latest .c-box, .mod_newsarchive .layout_latest .c-box {
    padding: 1rem; }
    .mod_newslist.news .layout_latest .c-box a.link-box, .mod_newsarchive .layout_latest .c-box a.link-box {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%; }
  .mod_newslist.news .layout_latest:hover figure img, .mod_newsarchive .layout_latest:hover figure img {
    transform: scale(1.05); }
  .mod_newslist.news .layout_latest:hover .c-box .more a, .mod_newsarchive .layout_latest:hover .c-box .more a {
    background: #ff6c37;
    color: white; }

.mod_newslist.news .link-archive, .mod_newsarchive .link-archive {
  width: 100%;
  padding-top: 1.5rem; }

@media (min-width: 769px) and (max-width: 1024px) {
  .mod_newslist.news .layout_latest, .mod_newsarchive .layout_latest {
    display: flex; }
  .mod_newslist.news figure, .mod_newsarchive figure {
    width: 41.66667%; }
  .mod_newslist.news .c-box, .mod_newsarchive .c-box {
    width: 58.33333%;
    display: flex;
    flex-direction: column;
    justify-content: center; } }

@media (min-width: 1025px) {
  .mod_newslist.news, .mod_newsarchive {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .mod_newslist.news h2, .mod_newsarchive h2, .mod_newslist.news .h2-low, .mod_newsarchive .h2-low, .mod_newslist.news .mod_article.teaser-subpages:not(.small) .ce_linkteaser h3, .mod_article.teaser-subpages:not(.small) .ce_linkteaser .mod_newslist.news h3, .mod_newsarchive .mod_article.teaser-subpages:not(.small) .ce_linkteaser h3, .mod_article.teaser-subpages:not(.small) .ce_linkteaser .mod_newsarchive h3 {
      width: 100%; }
    .mod_newslist.news .layout_latest, .mod_newsarchive .layout_latest {
      width: calc(33.333333% - 1rem); } }

.mod_newslist.jobs .layout_simple:not(.last) {
  margin-bottom: 0.5rem; }

.mod_login .widget-submit {
  margin-top: 1.5rem; }

@media (min-width: 769px) {
  .mod_newsreader.news .layout_full {
    display: flex;
    flex-wrap: wrap; }
    .mod_newsreader.news .layout_full > * {
      width: 100%; }
    .mod_newsreader.news .layout_full h1 {
      margin-bottom: calc( 6rem * 0.33); }
    .mod_newsreader.news .layout_full figure {
      width: 33.33333%;
      margin-top: 0.5rem; }
    .mod_newsreader.news .layout_full .ce_text {
      width: 66.66667%;
      padding-left: 2.5%; } }

.mod_newsarchive h1 {
  width: 100%; }

#footer {
  position: relative;
  padding: calc( 6rem * 0.5) 5%;
  width: 100%;
  color: rgba(255, 255, 255, 0.75);
  font-size: 1rem;
  background: #1c2c3a;
  overflow: hidden;
  z-index: 1;
  border-top: 1px solid #ff6c37; }
  #footer p {
    font-size: 1rem; }
  #footer .inside {
    margin: 0 auto;
    max-width: 1024px;
    width: 100%; }
    #footer .inside::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-bottom: 9999px solid #0a202d;
      border-right: 9999px solid transparent;
      pointer-events: none;
      z-index: -1; }
  #footer a {
    transition: 250ms ease-in-out; }
    #footer a:hover {
      color: white; }
  #footer .block {
    margin-bottom: 1.5rem; }
    #footer .block:last-child {
      margin-bottom: 0; }
  #footer .adress p:last-child {
    margin-bottom: 0; }
  #footer .copyline {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.5); }
  @media (min-width: 541px) {
    #footer .inside {
      display: flex;
      flex-wrap: wrap; }
    #footer .logo-container {
      width: 100%; }
    #footer .adress {
      width: 50%; }
    #footer .nav-footer {
      width: 50%;
      text-align: right; }
    #footer .copyline.block {
      width: 100%;
      text-align: left; } }
  @media (min-width: 769px) {
    #footer .logo-container {
      width: 25%; }
    #footer .adress {
      width: 50%;
      display: flex;
      flex-wrap: wrap; }
      #footer .adress p {
        width: 50%;
        padding-right: 1.5rem; }
    #footer .nav-footer {
      width: 25%;
      text-align: left; }
    #footer .copyline {
      padding-left: 25%; } }

.cookiebar {
  font-size: 1rem;
  background: #2e3a48;
  z-index: 9999; }

.cookiebar__button {
  background: #ff6c37;
  transition: 250ms ease-in-out; }
  .cookiebar__button:hover {
    background: white;
    color: #0a202d; }

.cookiebar__link {
  transition: 250ms ease-in-out;
  border-bottom: 1px solid white;
  text-decoration: none; }
  .cookiebar__link:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 0); }

.cookiebar__message {
  margin-right: 0; }
