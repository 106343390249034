.nav-contact {
  margin-left: auto;
  z-index: 9999;
  
  ul {
    display: flex;
  }
  
  ul > li {
    height: 4rem;
    width: 2.75rem;
    transition: background 250ms ease-in-out;
  }
  
  a {
    height: 4rem;
    width: 2.75rem;
    display: inline-block;
  }
  
  li.tel-contact {
    background: url('../../files/assets/icons/ixxact-icon-tel-bright.svg') no-repeat center center / 1.5rem auto;
    &:hover {
      background-image: url('../../files/assets/icons/ixxact-icon-tel-orange.svg');
    }
  }
  
  li.mail-contact {
    background: url('../../files/assets/icons/ixxact-icon-mail-bright.svg') no-repeat center center / 1.5rem auto;
    &:hover {
      background-image: url('../../files/assets/icons/ixxact-icon-mail-orange.svg');
    }
  }
  
  @include screen(large) {
    margin-left: 0;
    
    ul > li {
      height: 2.75rem;
      width: 2.25rem;
    }
    
    ul > li > a {
      height: 2.75rem;
      width: 2.25rem;
    }
    
    li.tel-contact {
      background-size: 1.25rem auto;
    }
    
    li.mail-contact {
      background-size: 1.25rem auto;
    }
  }
  
  @include screen(xlarge) {
    margin-left: 1rem;
  }
}