/// grid
$sideSpace: 5%;
$topBottomSpace: 6rem;

.rs-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  >h1,>h2,>h3,>h4,>h5,>h6 {
    width: 100%;
  }
}

.rs-column {
width: 100%;
$max-columns: 6;
$breaks: small, medium, large;

  @each $class-name in $breaks {
    @include screen($class-name) {
      @for $cols from 1 through $max-columns {
      $colWidth: (1 / $cols);
      $gutter: ($gutter-width * ($cols - 1) / $cols);
        @for $span from 1 through $cols {
          &.-#{unquote($class-name)}-col-#{$cols}-#{$span} {
            $width: ($colWidth * $span) * 100%;
            width: calc(#{$width} - #{$gutter};
          }  
        }
      }
    }
  }
}



