.ce_gallery {
  
  // -SMALL
  @include screen(-large) {
    ul > li {
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  // LARGE
  @include screen(large) {
    ul {  
      display: flex;
    }
    
    ul > li {
      margin-right: 0.25rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}