.mod_article.teaser-subpages {
  width: 100%;
  
  // HEADLINE
  .ce_headline {
    width: 100%;
    text-align: center;
    margin-bottom: calc( #{$topBottomSpace} * 0.33 );
  }
  
  // MEDIUM-
  @include screen(medium) {
    display: flex;
    flex-wrap: wrap;
    
    > .block {
      width: percentage(1/2);
    }
  }
  
  // LARGE-
  @include screen(large) {
    > .block {
      width: percentage(1/3);
    }
  }
}

.mod_article.teaser-subpages:not(.small) {
  // MAX-WIDTH
  margin: $topBottomSpace auto;
  padding: 0 $sideSpace;
  max-width: $max-width;
  
  // IMAGE
  .image_container {
    @include aspect-ratio(3,2);
  }
  
  // SUBHEADLINE
  .ce_linkteaser h3 {
    @extend h2;
    font-weight: 400;
  }
  
}

.mod_article.teaser-subpages.small {
  
  // MEDIUM-
  @include screen(-large) {
    > .block {
      width: 100%;
    }
  }

  // LARGE-XLARGE
   @include screen(large-xlarge) {
    > .block:nth-last-child(-n+2) {
      width: percentage(1/2);
    }
  }  
  
  // XLARGE-
  @include screen(xlarge) {
    > .block {
      flex: 1 0 auto;
      width: auto;
    }
  }
}