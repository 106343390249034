// wrapper
#wrapper {
  @supports (display: flex) {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
  
  overflow-x: hidden;
}

// container
#container {
  @supports (display: flex) {
    flex: 1 0 auto;
  }
  background: white;

  .no-header & {
    margin-top: 4.8125rem
  }
}
