.nav-sub {
  [itemprop="name"] {
    position: relative;
    display: inline-block;
    font-size: 1.125rem;    
    padding: 0.5rem 0.75rem;
    line-height: 100%;
    
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 40%;
      margin-left: 0.75rem;
      background: rgba($text-color, 0.5) !important;
    }
  }
  
  .last [itemprop="name"]::after {
    content: none;  
  }
}

.nav-main {

  a, span {
    text-decoration: none; // reset
    display: inline-block;
    color: white;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 100%;
    padding: 0.75rem;
    border-radius: 0.25rem;
    cursor: pointer;
    
    @include screen(large) {
      padding: 0.75rem 0.5rem;
    }
    
    @include screen(xlarge) {
      padding: 0.75rem;
    }
  }

  // level 1
  ul.level_1 > li > a, ul.level_1 > li > span {
    transition: 125ms ease-in-out;
    
    &:hover {
      background: $brand-color;
    }
  }
  
  // trail, active
  ul.level_1 span.trail,
  ul.level_1 span.active {
    font-weight: 700;
  }  
  
  // submenu
  ul.level_1 > li.submenu > {
    
    a, span {
      position: relative;
      padding-right: 1.875rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    
    &:hover a, &:hover span {
      background: $brand-color;
    }
    
    span::after {
      content: "";
      background: url('../../files/assets/icons/ixxact-icon-arrow-down.svg') no-repeat center / 0.75rem 0.75rem;
      top: 50%;
      right: 0;
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.75rem;
      transform: translateY(-50%);
      position: absolute;
      transition: transform 125ms ease-in-out;
    }
  }
  
  @include screen(-large) {
    ul.level_1 > li.submenu.open > span::after {
      transform: translateY(-50%) rotate(-90deg);
      right: 0.125rem;
    }
  }
  
  // level_2
  ul.level_2 a, ul.level_2 span {
    width: 100%;
    font-size: 1.125rem;
    font-weight: 400;
    min-width: 12.5rem;
    transition: 250ms ease-in-out;
    border-radius: 0;
    opacity: 0.66;
    
    &.first {
      padding-top: 1rem;
    }
    
    &.last {
      padding-bottom: 1rem;
    }
    
    &:hover {
      background: $brand-color-light;
      opacity: 1;
    }
    
    &.active {
      background: none;
      opacity: 1;
      cursor: default;
    }
  }
}