////// design
  //// fonts
    $text-font-stack: sans-serif !default;
    $code-font-stack: monospace !default;

  //// colors
    $text-color:            #0a202d;
    $brand-color:           #0a202d;
    $brand-color-light:     #1c2c3a;
    $brand-color-lighter:   #2e3a48;
    $accent-color:          #ff6c37;

  /// development
    $border-dev: 1px solid rgba(black,0.125);

  /// 

////// layout
  //// breakpoints
    $break-small:       320px !default;
    $break-smedium:     540px !default;
    $break-medium:      768px !default;
    $break-large:      1024px !default ;
    $break-xlarge:     1440px !default;
    $break-xxlarge:    2560px !default;

  //// container, wrapper
    $max-width: $break-xlarge;

  //// media-queries
    $mq-small:                small;            // s
    $mq-smedium:              smedium;          // sm
    $mq-smedium-medium:       smedium-medium;   // sm-m
    $mq-medium:               medium;           // m
    $mq-smaller-than-medium:  -medium;          // <m      
    $mq-medium-large:         medium-large;     // m-l
    $mq-smaller-than-large:   -large;           // <l    
    $mq-large:                large;            // l
    $mq-large-xlarge:         large-xlarge;     // l-xl
    $mq-xlarge:               xlarge;           // xl

  //// semantic-grid
    // settings
    $gridClass: false; // true or false // default false
    $gridMargin: false; // true or false // default false
    $gutter-width: 2.5%;

    // vars
    $col-margin: 1.5rem;
    $columns: 12; // default 12

    // media-queries
    $media-queries-list: (
      // "small": s,
      // "smedium": sm,
      // "smedium-medium": sm-m,
      // "medium": m,
      // "medium-large": m-l,
      // "large": l,
      // "large-xlarge": l-xl,
      // "xlarge": xl,
    );

  ////// debugging
    //// outlines
    @mixin debug {
      outline: 1px solid rgba(magenta, 0.25);
      outline: 0;
    }

    @mixin debug-tags {
      outline: 1px solid rgba(blue, 0.25);
      outline: 0;
    }

    @mixin debug-elements {
      outline: 1px solid rgba(green, 0.25);
      outline: 0;
    }

    @mixin debug-modules {
      outline: 1px solid rgba(red, 0.25);
      outline: 0;
    }