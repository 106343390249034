button {
  letter-spacing: 0.05rem;
  font-weight: 600;
  opacity: 1;
  text-transform: uppercase;
  font-size: 1rem;
  transition: 250ms ease-in-out;
  cursor: pointer;
  color: $accent-color;
  border: 1px solid $accent-color;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem 0;
  display: inline-block;
  
  &:hover {
    background: $accent-color;
    color: white;
  }
  
  &:focus {
    outline: none;
  }
  
  &::moz-focus-inner {
    border: 0;
  }
}

.hero .hero-content button {
  background: $accent-color;
  color: white;
}