.icon-hamburger {
  position: relative;

  &, &::before, &::after {
    width: 1.5rem;
    height: 0.1875rem;
    background: white;
    transition: 333ms ease-in-out;

    .show-mobile-menu & {
      background: $accent-color;
      width: 1.675rem;
    }
    
    .nav-mobile-trigger:hover & {
      background: $accent-color;
    }
  }

  &::before, &::after {
    content: "";
    position: absolute;
    left: 0;
    transform: rotate(0deg);
  }

  & {
    .show-mobile-menu & {
      background-color: transparent;
    }
    
    .show-mobile-menu .nav-mobile-trigger:hover & {
      background-color: transparent;
    }
  }

  &:before {
    top: -0.5rem;
    .show-mobile-menu & {
      top: 0;
      transform: rotate(45deg);
    }
  }

  &:after {
    top: 0.5rem;
    .show-mobile-menu & {
      top: 0;
      transform: rotate(-45deg);
    }
  }
}