// googleMaps
.dlh_googlemap  {
  position: relative;
  padding-bottom: 100%;
  height: 0; width: 100%;
  overflow: hidden;

  & > * {
    pointer-events: none; // disable scroll
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include screen(medium) {
    padding-bottom: 75%;
  }

  @include screen(large) {
    padding-bottom: 50%;
  }
}
