.ce_table {
  
  $tablePadding: 0.5rem;
  
  table {
    width: 100%;
    border: 1px solid rgba($brand-color, 0.125)
  }
  
  table, td, tr, th {
    border-collapse: collapse;
    text-align: left;
  }
  
  td, tr, th {
    padding: $tablePadding;
    border-collapse: collapse;
    font-size: 1.125rem;
  }

  th {
    border-bottom: 1px solid rgba($brand-color, 0.125);
    background: rgba($brand-color, 0.1);
    font-weight: 600;
  }
  
  th:not(.col_last), td:not(.col_last) {
    border-right: 1px solid rgba($brand-color, 0.125);
  }
  
  tr.even {
    background-color: rgba($brand-color, 0.05);
  }

  @include screen(-large) {
    
    table {
      border: 0;
    }
    
    thead {
      display: none;
    }
    
    td, tr, th, td:not(.col_last) {
      border: 0;
    }
    
    // td
    td:not(.col_last) {
      border-bottom: 1px solid rgba($brand-color, 0.125);
    }
        
    tr {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 1rem;
      padding: 0;
      border: 1px solid rgba($brand-color, 0.125);
      
      &.even {
        background: none;
      }
    }
    
    td {
      padding: $tablePadding;
      position: relative;
      padding-left: 8.5rem;
      
      &.col_last {
        border-bottom: 0;
      }
    }
    
    td::before {
      content: attr(title);
      overflow: hidden;
      width: 8rem;
      left: $tablePadding;
      position: absolute;
      height: 100%;
      font-weight: 600;
      border-right: 1px solid rgba($brand-color, 0.125);
      background: rgba($brand-color, 0.05);
      padding: $tablePadding;
      margin: -#{$tablePadding} $tablePadding -#{$tablePadding} -#{$tablePadding};
    }
  }
}