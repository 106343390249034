.mod_newslist.news {
  
  .layout_latest {
    margin-bottom: 1.5rem;
    position: relative;
    box-shadow: 1px 1px 6px rgba($brand-color, 0.2);
    
    // image
    figure {
      overflow: hidden;
      
      img {
        transform: scale(1.0);
        transform-style: preserve-3d;
        transition: 250ms ease-in-out;
        -webkit-backface-visibility: hidden;
      }
    }
    
    // content-box
    .c-box {
      padding: 1rem;
    
      a.link-box {
        position: absolute;
        top: 0; 
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
      }
    }
    
    // HOVER
    &:hover {
      
      figure img {
        transform: scale(1.05);
      }
      
      .c-box .more a {
        background: $accent-color;
        color: white;
      }
    }
  }
  
  .link-archive {
    width: 100%;
    padding-top: 1.5rem;
  }
  
  @include screen(large-xlarge) {
   
    .layout_latest {
      display: flex;
    }
    
    figure {
      width: percentage(5/12);
    }
    
    .c-box {
      width: percentage(7/12);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  
  @include screen(xlarge) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    h2 {
      width: 100%;
    }
    
    .layout_latest {
      width: calc(33.333333% - 1rem);
    }
  }
}

.mod_newslist.jobs {
  
  .layout_simple:not(.last) {
    margin-bottom: 0.5rem;
  }
}