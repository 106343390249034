.nav-main {
  position: relative;

  @include screen(-large) {
    padding: 7.5rem 0;
    
    ul.level_1 {
      margin: {
        left: $sideSpace;
        right: $sideSpace;
      }
    }
    
    ul.level_2 {
      display: none;
      
      a, span {      
        padding-left: 1.5rem;
        
        &:hover {
          background: none;
        }
      }
    }
    
    li.submenu.open ul.level_2 {
      display: block;
    }
    
    li {
      span, a {
        border-bottom: 1px solid rgba(white, 0.25);
        width: 100%;
      }
    }
  }
  
  
  @include screen(large) {
    margin-left: auto;
    
    ul.level_1 {
      display: flex;
    }

    ul.level_2 {
      position: absolute;
      background: $brand-color;
      color: white;
      z-index: 1;
      opacity: 0;
      pointer-events: none;
      transition: opacity 125ms ease-in-out;
      overflow: hidden;
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
      
    li.submenu:hover ul.level_2 {
      opacity: 1; 
      pointer-events: auto;
    }
  }
}