.ce_text.cta-contact {
background: $accent-color;
color: white;
  
  figure {
    margin-bottom: 0;
  }
  
  em {
    margin-left: 0.1rem;
  }
  
  h3, p {
    padding: 0 $sideSpace;
  }
  
  h3 {
    margin-bottom: 0;
    line-height: 1;
    font-size: 1.4em;
    font-weight: 600;
  }
  
  h3 + p .tel {
    margin-top: calc( #{$topBottomSpace} * 0.125 );
    display: inline-block;
  }
  
  h3 {
    padding-top: calc( #{$topBottomSpace} * 0.33 );
  }
    
  p:last-of-type {
     padding-bottom: calc( #{$topBottomSpace} * 0.33 );
  }
  
  .image_container {
    @include screen(large) {
      @include aspect-ratio(16,9);
    }
  }
}