#navigation {
  position: fixed;
  z-index: 9999;
  transform: translateZ(0);
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem calc(#{$sideSpace} * 0.5) 0.5rem $sideSpace;
  background: rgba($brand-color-lighter, 0);
  
  @include screen(large) {
    padding: 1rem calc(#{$sideSpace} * 0.5) 1rem $sideSpace;
  }
  
  .no-header &, &.sticky {
    background: $brand-color-lighter;
  }
  
  .show-mobile-menu & {
    background: $brand-color-lighter;
    transition: 250ms ease-in-out;
  }
}

// main navigation
.nav-main {
  display: none;
    
  @include screen(-large) {
    .has-mobile-menu & {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100vh;
      height: 100%;
      background: $brand-color;
      transition: opacity 250ms ease-in-out;
      opacity: 0;
      pointer-events: none;
      overflow: scroll;
      
      &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 7.5rem;
        background: $brand-color;
        background: linear-gradient(to bottom, $brand-color, $brand-color 5rem, rgba($brand-color, 0));
        z-index: 1;
      }
      
      .show-mobile-menu & {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  
  @include screen(large) {
    display: block;
  }
}
 
// nav-mobile-trigger
.nav-mobile-trigger {
  @include screen(large) {
    display: none;
  }
}