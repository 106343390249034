a {
  text-decoration: none;
  color: currentColor;
}

.container .ce_text:not(.cta-contact) a {
  color: $accent-color;
  border-bottom: 1px solid rgba($accent-color, 0);
  &:hover {
    border-bottom: 1px solid $accent-color;
  }
}

.cta-contact a {
  @extend button;
  color: white;
  border: 1px solid white;
  &:hover {
    color: $accent-color;
    background: white;
  }
}

// TEASER
a.more {
  @extend button;
}

// NEWS-TEASER
.more > a {
  @extend button;
}

// CE_HYPERLINK
.ce_hyperlink > a {
  @extend button;
}

// JOBLIST
.layout_simple > a {
  @extend button;
}

// BACKLINK
.mod_newslist .back {
  text-align: center;
}

.back > a {
  @extend button;
}