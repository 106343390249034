.nav-book {
  
  ul {
    display: flex;
    justify-content: center;
  }
  
  li {
    padding: 1.5rem;
  }
}
