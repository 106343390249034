#footer {
  position: relative;
  padding: calc( #{$topBottomSpace} * 0.5) $sideSpace;
  width: 100%;
  color: rgba(white, 0.75);
  font-size: 1rem;
  background: $brand-color-light;
  overflow: hidden;
  z-index: 1;
  border-top: 1px solid $accent-color;
  
  p {
    font-size: 1rem;
  }
    
  .inside {
    margin: 0 auto;
    max-width: $break-large;
    width: 100%;
    
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-bottom: 9999px solid $brand-color;
      border-right: 9999px solid transparent;
      pointer-events: none;
      z-index: -1;
    }
  }
  
  a {
    transition: 250ms ease-in-out;
    &:hover {
      color: white;
    }
  }
  
  // BLOCK
  .block {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  // ADRESS
  .adress p:last-child {
    margin-bottom: 0;
  }
  
  // COPYLINE
  .copyline {
    font-size: 0.875rem;
    color: rgba(white, 0.5);
  }
  
  // MEDIUM
  @include screen(medium) {
    .inside {
      display: flex;
      flex-wrap: wrap;
    }
    
    .logo-container {
      width: 100%;
    }
    
    .adress {
      width: 50%;
    }
    
    .nav-footer {
      width: 50%;
      text-align: right;
    }
    
    .copyline.block {
      width: 100%;
      text-align: left;
    }
  }
  
  // LARGE
  @include screen(large) {
    .logo-container {
      width: percentage(1/4);
    }
    
    .adress {
      width: percentage(2/4);
      display: flex;
      flex-wrap: wrap;
      
      p {
        width: 50%;
        padding-right: 1.5rem;
      }
    }
    
    .nav-footer {
      width: percentage(1/4);
      text-align: left;
    }
    
    .copyline {
      padding-left: percentage(1/4);
    }
  }
}

