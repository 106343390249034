%headings {
  @include debug-tags; // dev
  font-weight: 300;
  margin-bottom: calc( #{$topBottomSpace} * 0.167 ); 
}

h1, .h1 {
  @extend %headings; 
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 1.25;  
}

h2, .h2 {
  @extend %headings;
  text-transform: uppercase;
  font-size: 1.75rem;
  line-height: 1.375;  
}

.h2-low {
  @extend h2;
  text-transform: none;
  font-weight: 400;
  font-size: 2.125rem;
}

h3, .h3 {
  @extend %headings; 
  font-size: 1.375rem;
  line-height: 1.25;
  font-weight: 400;  
}