p {
  margin-bottom: calc( #{$topBottomSpace} * 0.125 );
  font-size: 1.125rem;
  line-height: 1.5;
  
  & .tel::before {
    content: "T ";
    font-weight: 700;
    font-size: 0.875em;
  }
  
  & .fax::before {
    content: "F ";
    font-weight: 700;
    font-size: 0.875em;
  }
  
  &.info {
    font-size: 1.125rem;
    color: rgba($brand-color, 0.5);
  }
}

i, em {
  font-style: italic;
}

b, strong {
  font-style: normal;
  font-weight: 600;
}