.mod_article {
  
  // CONTAINER
  &.container {
    margin: $topBottomSpace auto;
    padding: 0 $sideSpace;
    max-width: $max-width;
    
    > *.block:not(:last-child) {
      margin-bottom: calc( #{$topBottomSpace} * 0.5 );
    }
  }

  // BACKGROUND
  @at-root {
    %bg {
      margin: 0 auto;
      padding: $topBottomSpace $sideSpace;
      color: white;
      position: relative;
      z-index: 0;
      &::after {
        content: "";
        position: absolute;
        left: -9999px;
        right: -9999px;
        top: 0;
        bottom: 0;
        z-index: -1;
        background: red;
      }
    }
  }
  
  &.bg-orange {
    @extend %bg;
    &::after {
      background: $accent-color;
    }
    
    &:not(.cta-contact) .tel {
      font-size: 1.4em;
    }
  }
  
  &.bg-blue {
    @extend %bg;
    &::after {
      background: $brand-color;
    }
  } 
  
  &.max-width {
    max-width: $max-width;
    margin: 0 auto;
    padding: $topBottomSpace $sideSpace;
  }
}