.mod_article.teaser-subpages .ce_linkteaser {
  position: relative;
  background: $brand-color;
  text-align: center;
  
  // IMAGE
  .image_container {
    overflow: hidden;
    
    img {
      opacity: 0.33;
      transform: scale(1.0);
      transition: 375ms ease-in-out;
      -webkit-backface-visibility: hidden;
    }
  }
  
  // ce_linkteaser:hover img
  &:hover img {
    transform: scale(1.05);
    opacity: 1;
  }
  
  // CONTENT
  .content {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate3d(0, -50%, 0);
    transform-style: preserve-3d;
    opacity: 1;
    transition: 375ms ease-in-out;  
    
    h3 {
      color: white;
    }
  }
  
  // ce_linkteaser:hover content
  &:hover .content {
    opacity: 0;
    transform: translate3D(0, -35%, 0);
  }
  
  // TEASER
  .teaser {
    display: none;
  }
}

.mod_article.teaser-subpages.small .ce_linkteaser {
  z-index: 1;
  overflow: hidden;
  
  // HIDE ACTIVE ELEMENT
  &.active {
    display: none; 
  }
  
  // IMAGE
  .image_container {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    z-index: -1;
  }
  
  // CONTENT
  .content {
    position: relative;
    transform: translate3d(0, 0, 0);
    padding: 1.5rem $sideSpace;
    @include screen(large) {
      padding: 2.25rem $sideSpace;
    }
    @include screen(xlarge) {
      padding: 3rem $sideSpace;
    }
  }
}
