.ce_form {

  h2 {
    text-align: center;
  }

}

form {
  max-width: $break-medium;
  margin: 0 auto;

  .widget {
    margin-bottom: 0.5rem;
    position: relative;

    &-submit {
      text-align: center;
    }
  }

  label {
    position: absolute;
    top: 1.25rem;
    left: 1rem;
    transition: 250ms ease-in-out;
    opacity: 1;
  }

  input, textarea {
    border: 0;
    width: 100%;
    height: 100%;
    padding: 2rem 1rem 0.5rem 1rem;
    font-family: "source-sans-pro";
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: 0.01rem;
    box-shadow: 0 1px 0 rbga($accent-color, 0);
    transition: box-shadow 250ms ease-in-out;
    background: rgba($brand-color, 0.05);

    &:focus {
      outline: 0;
      box-shadow: 0 1px 0 $accent-color;
    }

    &:valid {
      box-shadow: 0 1px 0 $accent-color;
    }

    &[data-empty="false"] + label,
    &:valid + label,
    &:focus + label {
      top: 0.5rem;
      font-size: 1rem;
      opacity: 0.5;
    }
  }

  button.submit {
    @extend button;
    background: none; // reset
    border-radius: 0;
    font-family: "source sans pro";
  }
}

form.contact {

  input, textarea {
    background: $brand-color-lighter;
    color: white;
  }
}

fieldset.checkbox_container {
  border: 1px solid rgba(white, 0.33);
  padding: 1rem;
  margin-top: -0.5rem;

  label {
    position: static;
    font-size: 0.875em;
  }

  input.checkbox {
    width: auto;
    height: auto;

    &[data-empty="false"] + label,
    &:valid + label,
    &:focus + label {
      top: 0;
      font-size: 0.875em;
      opacity: 1;
    }
  }

  a {
    opacity: 0.66;
    transition: 250ms ease-in-out;
    border-bottom: 1px solid currentColor;
    &:hover {
      opacity: 1;
    }
  }
}
